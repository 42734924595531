import {alpha, Button, Container as MuiContainer, Paper, styled} from '@mui/material';

export const PaperLink = styled(Paper)(({ theme, color }) => ({
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'none',
    transition: theme.transitions.create('background'),
    background: alpha(theme.palette[color || 'primary'][theme.palette.mode], .5),
    '&:hover,&:focus': {
        background: alpha(theme.palette[color || 'primary'][theme.palette.mode], .75)
    }
}))

export const StuckTop = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: 56,
    background: theme.palette.background.paper,
    zIndex: 100,
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('sm')]: {
        top: 64,
    },
}));

export const StuckTopPaperHeader = styled(StuckTop)(({ theme }) => ({
    boxShadow: theme.shadows[0],
    '&:hover, &:focus': {
        boxShadow: theme.shadows[4],
    },
}));

export const PageContainer = styled(MuiContainer)(({ theme }) => ({
    padding: '1rem',
    position: 'relative',
}));

export const GridButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: '100%',
    textTransform: 'none',
    '& .icon': {
        marginRight: '1rem',
    },
    '& .text': {
        flexGrow: 1,
    },
})) as typeof Button;
