import { ApiError as ApiErrorProps } from '@mtechvault/ams-types';

export class ApiError {
    public readonly error?: number;

    public readonly code?: number;

    public readonly type?: string;

    public readonly message?: string;

    constructor(props: Partial<ApiErrorProps>) {
        // super(props.message);
        this.message = props.message;
        this.error = props.error;
        this.type = props.type;
        this.code = props.code;
    }
}
