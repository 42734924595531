import React, {ReactNode, useMemo} from 'react';
import {Backdrop as MuiBackdrop, Box} from '@mui/material';
import { styled } from '@mui/material/styles';

const Backdrop = styled(MuiBackdrop)(({ theme }) => ({
    position: 'absolute',
}));

type Props = {
    loading?: boolean
    children?: ReactNode;
};
function LoadingFallback(props: Props) {

    const backdrop = useMemo(() => (
        <Backdrop open={props.loading as boolean} sx={{ zIndex: 1000 }}>
            <img src={'/loading.svg'} width={75} />
            {/* <CircularProgress variant={'indeterminate'} /> */}
        </Backdrop>
    ), [props.loading])

    if (!props.children)
        return backdrop;

    console.log({ children: props.children, backdrop })

    return (
        <Box position={'relative'}>
            {backdrop}
            {props.children}
        </Box>
    );
}

LoadingFallback.defaultProps = {
    loading: true
}

export type LoadingFallbackProps = Props;
export default LoadingFallback;
