import { useCallback, useEffect } from 'react';

type Fn = (evt: KeyboardEvent) => unknown;

type Props = {};

const globalCache: {
    [s: string]: Fn;
} = {};

let globalAttached = false;

function useKeyboard(id: string, callback?: Fn | null, props?: Props) {
    const globalListener = useCallback((evt: KeyboardEvent) => {
        Object.values(globalCache).forEach((fn) => {
            if (evt.defaultPrevented) return;
            fn(evt);
        });
    }, []);

    useEffect(() => {
        if (id && callback) {
            globalCache[id] = callback;
        }

        if (!globalAttached && Object.keys(globalCache).length > 0) {
            globalAttached = true;
            document.addEventListener('keydown', globalListener);
        }

        return () => {
            delete globalCache[id];
            if (Object.keys(globalCache).length === 0) {
                globalAttached = false;
                document.removeEventListener('keydown', globalListener);
            }
        };
    }, [id, callback, globalListener]);
}

export type UseKeyboardProps = Props;
export default useKeyboard;
