import { MutableRefObject } from 'react';
import { Method } from 'axios';
import {
    ApiResponse,
    Struct,
    parsedEndpoint,
    JWTToken,
    RequestBody,
    EmailResponse,
} from '@mtechvault/ams-types';
import {ApiError} from './apiError';
import { parseDates, durationToMillis, apiFetch } from './index';

const parseResponse = async <T>(response) => {
    if (response.status < 300) {
        const resp = await response.text();
        try {
            const ret: T = JSON.parse(resp);
            return parseDates(ret);
        } catch (_) {
            return resp;
        }
    }

    const data: ApiResponse = await response.json();
    throw new ApiError(data);
};

export const UserFetch =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        return apiFetch(endpoint, { signal }).then(parseResponse) as Promise<
            ApiResponse<Struct.User>
        >;
    };

export const UserFetchJWT = (endpoint: string) =>
    apiFetch(endpoint).then(
        (response) => response.json() as Promise<ApiResponse<{ jwt: JWTToken }>>
    );

export type UserLoginArgs = {
    email: string;
    password: string;
};
export const UserLogin = async (noop: string, args: UserLoginArgs) => {
    const requestBody = new FormData();
    requestBody.append('email', args.email);
    requestBody.append('password', args.password);
    const response = await apiFetch(parsedEndpoint('userLogin'), {
        method: 'POST',
        body: requestBody,
    });

    const responseBody = await response.json();

    if (response.status !== 200) {
        throw new ApiError(responseBody);
    }

    return true;
};

export const UserRegister =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string, body: RequestBody.UserRegister) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        const form = new FormData();
        form.append('firstName', body.firstName);
        form.append('lastName', body.lastName);
        form.append('email', body.email);
        form.append('emailConfirm', body.emailConfirm);
        form.append('password', body.password);
        form.append('passwordConfirm', body.passwordConfirm);

        return apiFetch(endpoint, { signal, method: 'POST', body: form }).then(
            parseResponse
        ) as Promise<ApiResponse>;
    };

export const UserUpdate =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string, body: RequestBody.UserUpdate) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        const acceptedKeys = [
            'password',
            'newPassword',
            'newPasswordConfirm',
            'newEmail',
            'newEmailConfirm',
            'firstName',
            'lastName',
        ];

        const form = new FormData();
        Object.keys(body).forEach((key) => {
            if (acceptedKeys.indexOf(key) >= 0) form.append(key, `${body[key]}`);
        });

        return apiFetch(endpoint, { signal, method: 'PUT', body: form }).then(
            parseResponse
        ) as Promise<ApiResponse>;
    };

export const UserLogout =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        return apiFetch(endpoint, { signal }).then(parseResponse) as Promise<ApiResponse>;
    };

export const ResetPassword =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string, body: RequestBody.ResetPassword) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        const form = new FormData();
        if (body.newPassword) form.append('newPassword', body.newPassword);
        form.append('verification', body.verification);
        form.append('id', body.id);

        return apiFetch(endpoint, { signal, method: 'POST', body: form }).then(
            parseResponse
        ) as Promise<
            ApiResponse<{ type: 'reset-password'; email: string; success: boolean }>
        >;
    };

export const RecoverAccount =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string, body: RequestBody.RecoverAccount) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        const form = new FormData();
        form.append('email', body.email);

        return apiFetch(endpoint, { signal, method: 'POST', body: form }).then(
            parseResponse
        ) as Promise<ApiResponse<{ success: boolean }>>;
    };

export const UserOrganizationInvitations =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        return apiFetch(endpoint, { signal }).then(parseResponse) as Promise<
            ApiResponse<Struct.UserInvitation[]>
        >;
    };

export const HandleUserOrganizationInvitation =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        return apiFetch(endpoint, { signal, method: 'POST' }).then(
            parseResponse
        ) as Promise<ApiResponse>;
    };

export const LocationListFetch =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        return apiFetch(endpoint, { signal }).then(parseResponse) as Promise<
            ApiResponse<Struct.Location[]>
        >;
    };

export const LocationFetch =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        return apiFetch(endpoint, { signal }).then(parseResponse) as Promise<
            ApiResponse<Struct.Location>
        >;
    };

export const LocationRemove =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        return apiFetch(endpoint, { signal, method: 'delete' }).then(
            parseResponse
        ) as Promise<ApiResponse>;
    };

export const LocationMutate =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string, method: 'post' | 'put', body: RequestBody.MutateLocation) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        const form = new FormData();
        form.append('name', body.name);

        return apiFetch(endpoint, { signal, method, body: form }).then(
            parseResponse
        ) as Promise<ApiResponse<{ id: number }>>;
    };

export const OrganizationUserFetch =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        return apiFetch(endpoint, { signal }).then(parseResponse) as Promise<
            ApiResponse<Struct.OrganizationUser>
        >;
    };

export const PendingOrganizationUsersListFetch =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        return apiFetch(endpoint, { signal }).then(parseResponse) as Promise<
            ApiResponse<Struct.PendingOrganizationUser[]>
        >;
    };

export const RemovePendingOrganizationInvitation =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        return apiFetch(endpoint, { signal, method: 'DELETE' }).then(
            parseResponse
        ) as Promise<ApiResponse>;
    };

export const OrganizationUsersListFetch =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        return apiFetch(endpoint, { signal }).then(parseResponse) as Promise<
            ApiResponse<Struct.OrganizationUser[]>
        >;
    };

export const InviteOrganizationUser =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string, body: RequestBody.InviteBody) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        const form = new FormData();
        form.append('email', body.email);
        form.append('notify', `${body.notify}`);
        Object.keys(body.permissions).forEach((perm) => {
            form.append(perm, `${body.permissions[perm]}`);
        });

        return apiFetch(endpoint, { signal, method: 'POST', body: form }).then(
            parseResponse
        ) as Promise<ApiResponse<Struct.PendingOrganizationUser>>;
    };

export const UpdateOrganizationUser =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string, body: RequestBody.UpdateOrganizationUser) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        const form = new FormData();
        Object.keys(body).forEach((perm) => {
            form.append(perm, `${body[perm]}`);
        });

        return apiFetch(endpoint, { signal, method: 'PUT', body: form }).then(
            parseResponse
        ) as Promise<ApiResponse>;
    };

export const DevicePair =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string, body: RequestBody.DevicePair) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        const form = new FormData();
        form.append('name', body.name);
        form.append('hwid', body.hwid);
        if (body.location) form.append('locationId', `${body.location}`);
        if (body.playlist) form.append('playlistId', `${body.playlist}`);

        return apiFetch(endpoint, { signal, method: 'POST', body: form }).then(
            parseResponse
        ) as Promise<ApiResponse<Struct.Device>>;
        // .then(async (response) => {
        //     const data: ApiResponse<Struct.Device> = await response.json();
        //     if (response.status !== 200)
        //         throw new ApiError(data);
        //
        //     data.data = parseDates(data.data);
        //     return data;
        // })
    };

export const DeviceFetch =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        return apiFetch(endpoint, { signal }).then((response) => {
            return parseResponse<ApiResponse<Struct.DeviceExpanded>>(response)
        });
    };

export const DevicesListFetch =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        return (
            apiFetch(endpoint, { signal })
                // .then(parseResponse) as Promise<ApiResponse<Struct.DeviceWithSession[]>>
                .then(async (response) => {
                    const data: ApiResponse<Struct.DeviceExpanded[]> =
                        await response.json();
                    if (response.status !== 200) throw new ApiError(data);

                    data.data = parseDates(data.data);
                    return data;
                })
        );
    };

export const DevicesMutate =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string, body: RequestBody.DeviceMutate) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        const form = new FormData();
        if (body.name) form.append('name', `${body.name}`);
        if (body.location) form.append('locationId', `${body.location}`);
        if (typeof body.playlist !== 'undefined')
            form.append('playlistId', `${body.playlist}`);
        if (body.reload) form.append('reload', `${body.reload}`);

        return apiFetch(endpoint, { signal, method: 'PUT', body: form }).then(
            parseResponse
        );
    };

export const DeviceRemove =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        return apiFetch(endpoint, { signal, method: 'delete' }).then(
            parseResponse
        ) as Promise<ApiResponse>;
    };

export const SignageListFetch =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        return apiFetch(endpoint, { signal }).then(async (response) => {
            const data: ApiResponse<Struct.Signage[]> = await response.json();
            if (response.status !== 200) throw new ApiError(data);

            data.data = parseDates(data.data);
            return data;
        });
    };

export const PlaylistFetch =
    <Expanded extends boolean = false>(
        signalRef?: null | MutableRefObject<null | AbortController>
    ) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }
        return apiFetch(endpoint).then(async (response) => {
            const data: ApiResponse<Struct.ExpandedPlaylist> = await response.json();
            if (response.status !== 200) throw new ApiError(data);

            data.data = parseDates(data.data);
            return data;
        });
    };

/**
 * Example of a fetch api that can abort the request
 */
export const PlaylistListFetch =
    <Expanded extends boolean = false>(
        signalRef?: null | MutableRefObject<null | AbortController>
    ) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        type Response = [Expanded] extends true
            ? Struct.Playlist
            : Struct.ExpandedPlaylist;

        return apiFetch(endpoint, { signal }).then(async (response) => {
            const data: ApiResponse<Array<Response>> = await response.json();
            if (response.status !== 200) throw new ApiError(data);

            data.data = parseDates(data.data);
            return data;
        });
    };

export const PlaylistRemove =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        return apiFetch(endpoint, { signal, method: 'delete' }).then(
            parseResponse
        ) as Promise<ApiResponse>;
    };

export const PlaylistMutate =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string, method: 'post' | 'put', body: RequestBody.MutatePlaylist) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        const form = new FormData();
        form.append('name', body.name);
        form.append('array', JSON.stringify(body.array));
        if (typeof body.reload === 'boolean') form.append('reload', `${body.reload}`);

        return apiFetch(endpoint, { signal, method, body: form }).then(
            async (response) => {
                if (response.status < 300) {
                    const resp = await response.text();
                    try {
                        const ret: ApiResponse<{ id: number }> = JSON.parse(resp);
                        return ret;
                    } catch (_) {
                        return resp;
                    }
                }

                const data: ApiResponse = await response.json();
                throw new ApiError(data);
            }
        );
    };

export const SignageRemove =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        return apiFetch(endpoint, { signal, method: 'delete' }).then(
            parseResponse
        ) as Promise<ApiResponse>;
    };

export const SignageFetch = (endpoint: string) => {
    return apiFetch(endpoint).then(async (response) => {
        const data: ApiResponse<Struct.Signage> = await response.json();
        if (response.status !== 200) throw new ApiError(data);

        data.data = parseDates(data.data);
        return data;
    });
};

export const SignageMutate =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string, method: 'post' | 'put', body: RequestBody.MutateSignage) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        const form = new FormData();
        form.append('name', body.name);
        form.append('type', body.type);
        form.append('timeout', `${durationToMillis(body.timeout)}`);
        form.append('weight', `${body.weight}`);
        form.append('startDate', body.startDate?.toISO() || 'NULL');
        form.append('endDate', body.endDate?.toISO() || 'NULL');
        form.append('dynamic', `${body.dynamic}`);
        if (typeof body.signage === 'string' || typeof body.signage === 'number')
            form.append('signage', `${body.signage}`);
        else if (body.signage !== null) {
            form.append('signage', body.signage);
        }
        // form.append('', body.)

        return apiFetch(endpoint, { signal, method, body: form }).then(
            async (response) => {
                if (response.status < 300) {
                    const resp = await response.text();
                    try {
                        const ret: ApiResponse<{ id: number }> = JSON.parse(resp);
                        return ret;
                    } catch (_) {
                        return resp;
                    }
                }

                const data: ApiResponse = await response.json();
                throw new ApiError(data);
            }
        );
    };

export const FetchInteractiveEmail =
    (signalRef?: null | MutableRefObject<null | AbortController>) =>
    (endpoint: string) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        return apiFetch(endpoint, { signal, method: 'POST' }).then(
            parseResponse
        ) as Promise<ApiResponse<EmailResponse>>;
    };

export const OrganizationFetchOne =
    (signalRef?: null |MutableRefObject<null | AbortController>) =>
        (endpoint: string) => {
            let signal: AbortSignal | undefined;
            if (signalRef) {
                if (signalRef.current) signalRef.current.abort();
                signalRef.current = new AbortController();
                signal = signalRef.current.signal;
            }

            return apiFetch(endpoint, { signal, method: 'GET' }).then(
                parseResponse
            ) as Promise<ApiResponse<Struct.OrganizationWithOrganizationUser>>
        }

export const OrganizationFetchMany =
    (signalRef?: null |MutableRefObject<null | AbortController>) =>
        (endpoint: string) => {
            let signal: AbortSignal | undefined;
            if (signalRef) {
                if (signalRef.current) signalRef.current.abort();
                signalRef.current = new AbortController();
                signal = signalRef.current.signal;
            }

            return apiFetch(endpoint, { signal, method: 'GET' }).then(
                parseResponse
            ) as Promise<ApiResponse<Struct.OrganizationWithOrganizationUser[]>>
        }

export const ApiFetch =
    <R extends ApiResponse, B = undefined>(
        signalRef?: null | MutableRefObject<null | AbortController>
    ) =>
    (endpoint: string, method: Method = 'GET', body?: B): Promise<R> => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }

        let form: FormData | undefined;
        if (body) {
            form = new FormData();
            Object.keys(body).forEach((key) => {
                form?.append(key, `${body[key]}`);
            });
        }

        return apiFetch(endpoint, { signal, method, body: form }).then(
            parseResponse
        ) as Promise<R>;
    };
