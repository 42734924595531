import { SetStateAction, useCallback, useState } from 'react';

type StoreType = 'local' | 'session';
type Props<V> = {
    shouldStore?: boolean;
    key: string;
    type?: StoreType;
    namespace?: string;
    defaultValue?: V;
};

type FnDispatch<A> = (prev: A) => A
type CustomDispatch<A> = (value: A | FnDispatch<A>, store?: boolean) => void;

function useStateStorage<V>(props: Props<V>): [V, CustomDispatch<V>, boolean] {
    const { type, namespace, defaultValue, key, shouldStore } = props || {};
    const [state, setState] = useState(
        window.store[type || 'session']
            .namespace(namespace || 'default')
            .get(key, defaultValue)
    );
    const [stored, setStored] = useState(
        typeof window.store[type || 'session']
            .namespace(namespace || 'default')
            .get(key) !== 'undefined'
    );

    const setter = useCallback(
        (
            value: SetStateAction<V>,
            condition = typeof shouldStore === 'boolean' ? shouldStore : true
        ) => {
            let storedValue = value;
            if (typeof value === 'function') {
                storedValue = (value as any)(state)
            }
            setState(value);
            if (condition) {
                if (typeof storedValue === 'undefined') {
                    setStored(false);
                    window.store[type || 'session']
                        .namespace(namespace || 'default')
                        .remove(key);
                } else {
                    setStored(true);
                    window.store[type || 'session']
                        .namespace(namespace || 'default')
                        .set(key, storedValue);
                }
            }
        },
        [state, shouldStore]
    );

    return [state, setter, stored];
}

export type UseStateStorageProps<V> = Props<V>;
export default useStateStorage;
