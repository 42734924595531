import React, { SyntheticEvent, useState } from 'react';
import {
    IconButton,
    Chip as MuiChip,
    Avatar,
    CircularProgress,
    CardContent,
    Card,
    Typography,
    CardActions,
    Button,
    styled,
    darken,
    lighten,
    Stack,
    Box,
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as UnfavoritedIcon from '@fortawesome/pro-regular-svg-icons/faStar';
import * as FavoritedIcon from '@fortawesome/pro-solid-svg-icons/faStar';
import * as Checked from '@fortawesome/pro-regular-svg-icons/faCheck';
import * as Unchecked from '@fortawesome/pro-regular-svg-icons/faTimes';
import { Navigate, Link } from 'react-router-dom';
import { TFunction, useTranslation } from 'react-i18next';
import { AccessLevels, Struct, FULL_DATE_FORMAT } from '@mtechvault/ams-types';

const favoriteStatus = {
    checked: FavoritedIcon.definition,
    unchecked: UnfavoritedIcon.definition,
};

const accessStatus = {
    checked: Checked.definition,
    unchecked: Unchecked.definition,
};

const UndecoratedLink = styled(Link)({
    textDecoration: 'none',
});

const Organization = styled(Card)({
    position: 'relative',
    minWidth: 275,
    marginBottom: '1rem',
    cursor: 'pointer',
    // '&:hover': {
    //     background: 'green'
    // }
});

const FavoriteContainer = styled('div')({
    position: 'absolute',
    right: 0,
    top: 0,
    padding: '.5rem',
});

const PendingIcon = styled(CircularProgress)({
    position: 'absolute',
    right: '0.8rem',
    top: '0.8rem',
    color: 'rgba(63, 81, 181, 0.25)',
});

const darkOrLight = (mode: 'light' | 'dark') => (mode === 'light' ? darken : lighten);
const Chip = styled(MuiChip)(({ theme }) => ({
    margin: '.25rem',
    background: darkOrLight(theme.palette.mode)(theme.palette.background.paper, 0.1),
}));

type Props = Struct.OrganizationWithOrganizationUser & {
    onFavorite?: (organization: Struct.Organization) => Promise<any>;
    onClick?: (organization: Struct.Organization) => void;
};

function AccessChips(
    t: TFunction<Array<'organization' | 'common'>>,
    access: AccessLevels | undefined
) {
    if (typeof access === 'undefined') return <span />;

    return Object.keys(access).map((key) => {
        const state = access[key as never];
        let avatar = <Avatar>{key.charAt(0).toUpperCase()}</Avatar>;
        let label = t(`common:permissions.${key}`);
        // label = label.charAt(0).toUpperCase() + label.substr(1)
        if (typeof state === 'boolean') {
            const icon = accessStatus[state ? 'checked' : 'unchecked'];
            const color = state ? 'limegreen' : 'orangered';
            avatar = (
                <Avatar style={{ background: color, color: 'white' }}>
                    <FontAwesomeIcon icon={icon} />
                </Avatar>
            );
            // color = state ? "primary" : "secondary";
        }
        return <Chip key={key} avatar={avatar} label={label} />;
    });
}

function OrganizationCard(props: Props) {
    const { user, id, name, modifiedAt, onFavorite, onClick } = props;
    const { t } = useTranslation(['organization', 'common']);
    const [processingFavorite, setProcessingFavorite] = useState(false);

    // const isFavorite = user.defaultOrganizationId === id;
    const isFavorite = false;
    const favoriteIcon = favoriteStatus[isFavorite ? 'checked' : 'unchecked'];
    const onChangeFavorite = (evt: SyntheticEvent) => {
        evt.preventDefault();
        evt.stopPropagation();
        if (typeof onFavorite === 'function') {
            setProcessingFavorite(true);
            // only call change if selected is currently not the favorite.
            onFavorite(props).then((result: any) => {
                setProcessingFavorite(false);
            });
        }
    };

    const onCardClicked = (evt: SyntheticEvent) => {
        if (typeof onClick === 'function') {
            evt.preventDefault();
            onClick(props);
        }
    };

    const onBtnClicked = (evt: SyntheticEvent) => {
        evt.stopPropagation();
        if (typeof onClick === 'function') {
            evt.preventDefault();
            onClick(props);
        }
    };

    return (
        <UndecoratedLink to={`/o/${props.id}/home`} onClick={onCardClicked}>

            <Organization variant="outlined">
                <FavoriteContainer>
                    {processingFavorite && <PendingIcon />}
                    <IconButton onClick={onChangeFavorite} size="large">
                        <FontAwesomeIcon icon={favoriteIcon} />
                    </IconButton>
                </FavoriteContainer>
                <CardContent sx={{ paddingBottom: 0 }}>
                    <Typography variant={'h4'} color="textSecondary" gutterBottom>
                        {name}
                    </Typography>
                    <Stack gap={2} direction={'row'} alignItems={'center'}>
                        <Typography
                            sx={{ minWidth: '7.5em', marginRight: '.5rem' }}
                            noWrap
                        >
                            {t('portal.content.item.access')}
                        </Typography>
                        <Stack
                            direction={'row'}
                            flexWrap={'wrap'}
                            children={AccessChips(t, user.rights)}
                        />
                    </Stack>
                    <Box marginTop={1}>
                        <Typography variant={'subtitle2'}>
                            {t('portal.content.item.lastModified', {
                                date: modifiedAt.toFormat(FULL_DATE_FORMAT),
                            })}
                        </Typography>
                    </Box>
                </CardContent>
                <CardActions>
                    <Button
                        onClick={onBtnClicked}
                        variant={'contained'}
                        focusRipple
                        color={'primary'}
                        size="small"
                    >
                        {t('portal.content.item.actions.select')}
                    </Button>
                </CardActions>
            </Organization>
        </UndecoratedLink>
    );
}

export default OrganizationCard;
