import { useEffect, useRef } from 'react';

function usePrevious<V = unknown>(value: V) {
    const ref = useRef<V>();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}

export default usePrevious;
