import { DateTime } from 'luxon';
import {Nullable, ClientRoute, IntegrationType, Struct, AccessLevels} from '@mtechvault/ams-types';

export function apiFetch(input: RequestInfo, init?: RequestInit) {
    if (process.env.NODE_ENV === 'development') {
        return new Promise((resolve) => {
            setTimeout(resolve, 1500);
        }).then(() => fetch(input, init));
    }
    return fetch(input, init);
}

import { SyntheticEvent } from 'react';

export function dummyEvt(name?: string): SyntheticEvent {
    return {
        currentTarget: { name },
        target: { name },
    } as never;
}



export function millisToReadable(ms: number) {
    let seconds = ms / 1000; // .toFixed(1);
    let minutes = ms / (1000 * 60); // .toFixed(1);
    let hours = ms / (1000 * 60 * 60); // .toFixed(1);
    let days = ms / (1000 * 60 * 60 * 24); // .toFixed(1);
    if (seconds < 60) return `${seconds.toFixed(1)} Sec`;
    if (minutes < 60) return `${minutes.toFixed(1)} Min`;
    if (hours < 24) return `${hours.toFixed(1)} Hrs`;
    return `${days} Days`;
}

export function millisToDuration(millis: number) {
    let millStr = Math.floor(millis / 1000).toString();
    let secs = Number.parseInt(millStr, 10);
    let mins = 0;
    let hours = 0;
    while (secs >= 60) {
        // eslint-disable-next-line
        mins++;
        secs -= 60;
    }
    while (mins >= 60) {
        // eslint-disable-next-line
        hours++;
        mins -= 60;
    }

    return `${`${hours}`.padStart(2, '0')}:${`${mins}`.padStart(
        2,
        '0'
    )}:${`${secs}`.padStart(2, '0')}`;
}

export function millisToDisplay(millis: number | string) {
    if (typeof millis !== 'number') return millis;
    const seconds = millis / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    if (days >= 1) return `${days.toPrecision(3)} day${days >= 2 ? 's' : ''}`;
    if (hours >= 1) return `${hours.toPrecision(3)} hour${hours >= 2 ? 's' : ''}`;
    if (minutes >= 1) return `${minutes.toPrecision(3)} minute${minutes >= 2 ? 's' : ''}`;
    if (seconds >= 1) return `${seconds.toPrecision(3)} second${seconds >= 2 ? 's' : ''}`;
    return `${millis.toPrecision(3)} millisecond${millis >= 2 ? 's' : ''}`;
}

export function durationToMillis(value: string) {
    const [hrs, mins, secs] = value
        .split(':')
        .map((k) => k.substr(0, k.indexOf(' ') >= 0 ? k.indexOf(' ') : k.length))
        .map((i) => Number.parseInt(i, 10) || 0);
    // .map(i => Number.parseInt(i.trim()))

    return secs * 1000 + mins * 60 * 1000 + hrs * 60 * 60 * 1000;
}


// TODO check types for related
type ActiveItem = {
    name?: string;
    endDate: string | Nullable<DateTime>;
    startDate: string | Nullable<DateTime>;
    active?: boolean;
};
export function isItemActive(item: ActiveItem) {
    const endDateDiff =
        (typeof item.endDate === 'object' && item.endDate
                ? item.endDate
                : DateTime.fromISO(item.endDate || '')
        ).diffNow('milliseconds').milliseconds || 0;
    const startDateDiff =
        (typeof item.startDate === 'object' && item.startDate
                ? item.startDate
                : DateTime.fromISO(item.startDate || '')
        ).diffNow('milliseconds').milliseconds || 0;

    const isBeforeStartDate = startDateDiff > 0;
    const isPastEndDate = endDateDiff < 0;

    return !isPastEndDate && !isBeforeStartDate;
}

export function isDOMChild(
    parentObj: HTMLElement | Node | null,
    obj: HTMLElement | Node | null
) {
    while (
        obj !== undefined &&
        obj !== null &&
        (obj as HTMLElement).tagName?.toUpperCase() !== 'BODY'
        ) {
        if (obj === parentObj) {
            return true;
        }
        // eslint-disable-line
        obj = obj.parentNode;
    }
    return false;
}

export function hasOrganizationAccess(item?: ClientRoute, organization?: Struct.OrganizationWithOrganizationUser) {
    if (typeof item === 'undefined')
        return true;
    if (typeof organization === 'undefined')
        return false;

    if (typeof item.access === 'undefined' || (
        Object.keys(item.access.rights?.use || {}).length === 0 &&
        Object.keys(item.access.integrations?.use || {}).length === 0
    ))
        return true;

    let accessible: boolean;
    if (item.access.rights) {
        const mode = item.access.rights.mode || 'AND';
        const containedRights = item.access.rights.use.filter((right) => organization.user.rights[right]);
        if (mode === 'AND')
            accessible = containedRights.length === item.access.rights.use.length
        else
            accessible = containedRights.length > 0
    } else {
        accessible = true
    }

    if (accessible && item.access.integrations && organization.integrations &&  Object.keys(organization.integrations).length > 0) {
        const mode = item.access.integrations.mode || 'AND';
        const containedIntegrations = item.access.integrations.use.filter((integration) => {
            if (!organization.integrations) {
                return false
            }
            return Boolean(organization.integrations[integration as IntegrationType])
        })


        if (mode === 'AND')
            accessible = containedIntegrations.length === item.access.integrations.use.length
        else
            accessible = containedIntegrations.length > 0

        // console.log(item.nameKey, accessible, containedIntegrations, organization.integrations, item.access.integrations)
    } else if (item.access.integrations && Object.keys(item.access.integrations).length > 0) {
        accessible = false;
    }

    return accessible;
}

export function capitalize(str?: string) {
    if (typeof str !== 'string' || str.length === 0) return str;
    return str.split(' ').map((word) => (
        word.charAt(0).toUpperCase() + word.substr(1)
    )).join(' ')
}

export function parseQuerystring(search: string) {
    const query: any = {};
    if (search.charAt(0) === '?') {
        search
            .substr(1)
            .split('&')
            .map((kvp) => {
                const parts = kvp.split('=') as any[];
                query[parts[0]] =
                    typeof parts[1] === 'undefined' ? true : decodeURIComponent(parts[1]);
            });
    }
    return query;
}

// TODO move to types
const datePattern = /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])/;

export function parseDates(data?: Object | Object[]): any {
    if (data) {
        let wasSingle = false;
        if (!(data instanceof Array)) {
            // eslint-disable-line
            data = [data];
            wasSingle = true;
        }

        // eslint-disable-line
        data = (data as Object[]).map((obj) => {
            Object.keys(obj).forEach((key) => {
                let value = obj[key as never] as any;
                if (value instanceof Array || typeof value === 'object') {
                    value = parseDates(value);
                }

                if (typeof value === 'string') {
                    if (value.match(datePattern)) {
                        // eslint-disable-line
                        (obj[key as never] as any) = DateTime.fromISO(value);
                    }
                }
            });

            return obj;
        });

        if (wasSingle) return (data as Object[])[0];
        return data;
    }
    return null;
}


export default {
    capitalize,
    parseQuerystring,
    parseDates,
    millisToReadable,
    millisToDuration,
    millisToDisplay,
    durationToMillis,
    isItemActive,
    isDOMChild,
    hasOrganizationAccess,
    apiFetch,
    dummyEvt,
}
