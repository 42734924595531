import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

type PromiseFn<R> = (...args: any[]) => Promise<R>;

type Props = {
    resolve?: boolean;
    args?: any[];
};

function usePromise<R = Object, E = Object>(promise: PromiseFn<R>, props: Props = {}) {
    const { resolve, args } = props;
    const [data, setData] = useState<null | R>(null);
    const [error, setError] = useState<null | E>(null);
    const [loading, setLoading] = useState(false);
    const lastUpdate = useRef<null | number>(null);
    const resolved = useMemo(() => data !== null, [data]);
    // const resolved = useRef(false);

    const execute = useCallback(
        (...providedArgs: any[]) => {
            setError(null);
            setData(null);
            setLoading(true);
            promise(...[...(args || []), ...(providedArgs || [])])
                .then((response) => {
                    setData(response);
                    setError(null);
                    lastUpdate.current = Date.now();
                })
                .catch((err) => {
                    setError(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [promise, args]
    );

    const reset = useCallback(() => {
        setData(null);
        setError(null);
    }, []);

    useEffect(reset, [args]);

    useEffect(() => {
        if (!(resolve && !resolved)) {
            return;
        }

        execute();
    }, [resolve, resolved]);

    return {
        resolved,
        execute,
        lastUpdate: lastUpdate.current,
        data,
        error,
        loading,
        reset,
    };
}

export type UsePromiseProps = Props;
export default usePromise;
