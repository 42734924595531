import React, {ReactNode} from 'react'
import {Tooltip, TooltipProps} from "@mui/material";

type Props = Omit<TooltipProps, 'title'> & {
    title?: ReactNode
    disabled?: boolean;
}

function Popover(props: Props) {
    const { title, children, disabled, ...tooltipProps } = props;

    if (disabled)
        return children;
    return (
        <Tooltip title={!!title ? title : ''} {...tooltipProps}>
            {children}
        </Tooltip>
    )
}

export type PopoverProps = Props;
export default Popover;
